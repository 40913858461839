import { animate, state, style, transition, trigger } from '@angular/animations';

export const expandAnimation = trigger('expandAnimation', [
  state(
    '*',
    style({ height: 0, overflow: 'hidden', 'margin-top': 0, 'margin-bottom': 0, 'padding-top': 0, 'padding-bottom': 0 })),
  state(
    'true',
    style({ height: '*', 'margin-top': '*', 'margin-bottom': '*', 'padding-top': '*', 'padding-bottom': '*' })),
  state(
    'false',
    style({ height: 0, overflow: 'hidden', 'margin-top': 0, 'margin-bottom': 0, 'padding-top': 0, 'padding-bottom': 0 })),
  state(
    'disabled',
    style({ height: '*', overflow: 'unset', 'margin-top': '*', 'margin-bottom': '*', 'padding-top': '*', 'padding-bottom': '*' })),
  transition('true => false', [
    // eslint-disable-next-line max-len
    style({ height: '*', overflow: 'hidden', 'overflow-y': 'hidden', 'margin-top': '*', 'margin-bottom': '*', 'padding-top': '*', 'padding-bottom': '*' }),
    animate(
      '400ms cubic-bezier(0.4,0.0,0.2,1)',
      style({ height: 0, overflow: 'hidden', 'margin-top': 0, 'margin-bottom': 0, 'padding-top': 0, 'padding-bottom': 0 })),
  ]),
  transition('false => true', [
    style({overflow: 'hidden', 'overflow-y': 'hidden'}),
    animate(
      '400ms cubic-bezier(0.4,0.0,0.2,1)',
      style({ height: '*', 'margin-top': '*', 'margin-bottom': '*', 'padding-top': '*', 'padding-bottom': '*' })),
  ]),
]);

export const expandAnimation1 = trigger('expandAnimation1', [
  transition(':enter', [
    style({opacity: 0, height: 0, overflow: 'hidden', 'margin-top': 0, 'margin-bottom': 0, 'padding-top': 0, 'padding-bottom': 0}),
    animate('300ms',
      style({opacity: 1, height: '*', 'margin-top': '*', 'margin-bottom': '*', 'padding-top': '*', 'padding-bottom': '*'})
    )
  ]),
  transition(':leave', [
    // eslint-disable-next-line max-len
    style({opacity: 1, height: '*', overflow: 'hidden', 'margin-top': '*', 'margin-bottom': '*', 'padding-top': '*', 'padding-bottom': '*'}),
    animate('300ms',
      style({opacity: 0, height: 0, overflow: 'hidden', 'margin-top': 0, 'margin-bottom': 0, 'padding-top': 0, 'padding-bottom': 0})
    )
  ])
]);
